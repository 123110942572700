// extracted by mini-css-extract-plugin
export var animationOne = "gorzka-presentation-module--animation-one--895fc";
export var animationTwo = "gorzka-presentation-module--animation-two--a69cb";
export var badge = "gorzka-presentation-module--badge--7e860";
export var bottle = "gorzka-presentation-module--bottle--6750a";
export var bottleAnimation = "gorzka-presentation-module--bottle-animation--ca8c0";
export var box = "gorzka-presentation-module--box--38057";
export var classicTheme = "gorzka-presentation-module--classicTheme--41c2c";
export var container = "gorzka-presentation-module--container--468cb";
export var description = "gorzka-presentation-module--description--bdfab";
export var errorBlink = "gorzka-presentation-module--error-blink--f86af";
export var icon = "gorzka-presentation-module--icon--10eb8";
export var leaf = "gorzka-presentation-module--leaf--bafbb";
export var mintTheme = "gorzka-presentation-module--mintTheme--21f36";
export var point = "gorzka-presentation-module--point--244f3";
export var section = "gorzka-presentation-module--section--af117";
export var slider = "gorzka-presentation-module--slider--d5fe1";
export var title = "gorzka-presentation-module--title--4fecd";
export var trait = "gorzka-presentation-module--trait--14961";
export var wrapper = "gorzka-presentation-module--wrapper--82380";