// extracted by mini-css-extract-plugin
export var animationOne = "gorzka-preview-module--animation-one--9982e";
export var animationTwo = "gorzka-preview-module--animation-two--8ab07";
export var bottle = "gorzka-preview-module--bottle--ad457";
export var bottleLeft = "gorzka-preview-module--bottle-left--9b577";
export var bottleRight = "gorzka-preview-module--bottle-right--07a32";
export var box = "gorzka-preview-module--box--8cb64";
export var button = "gorzka-preview-module--button--36ab0";
export var classic = "gorzka-preview-module--classic--13946";
export var container = "gorzka-preview-module--container--c0456";
export var description = "gorzka-preview-module--description--6ddc8";
export var endTheme = "gorzka-preview-module--end-theme--a72f8";
export var errorBlink = "gorzka-preview-module--error-blink--5e7da";
export var icon = "gorzka-preview-module--icon--26aaa";
export var image = "gorzka-preview-module--image--bf171";
export var imagesContainer = "gorzka-preview-module--images-container--7a1ac";
export var mint = "gorzka-preview-module--mint--2e478";
export var sectionTitle = "gorzka-preview-module--section-title--56468";
export var splash = "gorzka-preview-module--splash--9cf87";
export var startTheme = "gorzka-preview-module--start-theme--28d17";
export var title = "gorzka-preview-module--title--f28c5";
export var wrapper = "gorzka-preview-module--wrapper--525bf";