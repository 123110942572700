// extracted by mini-css-extract-plugin
export var card = "gorzka-drinks-module--card--bb115";
export var container = "gorzka-drinks-module--container--c7b47";
export var content = "gorzka-drinks-module--content--42a10";
export var description = "gorzka-drinks-module--description--765e4";
export var errorBlink = "gorzka-drinks-module--error-blink--f5449";
export var firstAnimation = "gorzka-drinks-module--first-animation--65101";
export var icon = "gorzka-drinks-module--icon--7a1bb";
export var secondAnimation = "gorzka-drinks-module--second-animation--62dd6";
export var section = "gorzka-drinks-module--section--fa970";
export var slider = "gorzka-drinks-module--slider--44ab1";
export var title = "gorzka-drinks-module--title--81db9";
export var videoPlayer = "gorzka-drinks-module--video-player--9dc6b";
export var videoRatio = "gorzka-drinks-module--video-ratio--7b698";