import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    classicTheme,
    mintTheme,
    image,
    title,
    list,
    item,
} from './gorzka-card.module.scss';

import ArrowIcon from '../../assets/images/svg/chevron-right.svg';

type TDrinksImages = 'discowrotki' | 'grazka' | 'tango' | 'wyprawka' | 'mohito';

export interface IGorzkaCard {
    type: 'classic' | 'mint';
    img: TDrinksImages;
    name: string;
    ingredients: string[];
}

interface IGorzkaCardProps {
    card: IGorzkaCard;
    className?: string;
}

const drinkClassicDiscowrotki = '../../assets/images/soplica-gorzka/classic-drink-discowrotki.jpg';
const drinkClassicGrazka = '../../assets/images/soplica-gorzka/classic-drink-grazka.jpg';
const drinkClassicTango = '../../assets/images/soplica-gorzka/classic-drink-tango.jpg';
const drinkMintMohito = '../../assets/images/soplica-gorzka/mint-drink-mohito.jpg';
const drinkMintWyprawka = '../../assets/images/soplica-gorzka/mint-drink-wyprawka.jpg';

const GorzkaCard: React.FC<IGorzkaCardProps> = ({ card, className }) => {
    return (
        <div className={`${container} ${className} ${getThemeClass(card.type)}`}>
            {card.img === 'discowrotki' && (
                <StaticImage className={image} src={drinkClassicDiscowrotki} alt={card.name} />
            )}
            {card.img === 'grazka' && (
                <StaticImage className={image} src={drinkClassicGrazka} alt={card.name} />
            )}
            {card.img === 'tango' && (
                <StaticImage className={image} src={drinkClassicTango} alt={card.name} />
            )}
            {card.img === 'wyprawka' && (
                <StaticImage className={image} src={drinkMintMohito} alt={card.name} />
            )}
            {card.img === 'mohito' && (
                <StaticImage className={image} src={drinkMintWyprawka} alt={card.name} />
            )}
            <p className={title}>{card.name}</p>
            <ul className={list}>
                {card.ingredients.map((ingredient, i) => {
                    return (
                        <li className={item} key={`drink-ingredient-${i}`}>
                            <ArrowIcon /> <span>{ingredient}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

function getThemeClass(type: IGorzkaCard['type']) {
    if (type === 'classic') {
        return classicTheme;
    }
    return mintTheme;
}

export default GorzkaCard;
